import { ICustomer } from "./IDriver";

export class Dashboard {
  data: Data;
  bookingPercentage: BookingPercentage;
  todaysOrders: Todays[];
  todaysDeliveries: Todays[];
  todaysPickups: Todays[];
  todaysCompleted: Todays[];
  salesThisYear: SalesYear[];
  salesLastYear: SalesYear[];
  todaysOrdersCustomers?: ICustomer[];
  todaysDeliveriesCustomers?: ICustomer[];
  todaysPickupsCustomers?: ICustomer[];
  todaysCompletedCustomers?: ICustomer[];
}

export class AccCustomerPartialPayment {
  orderId: number;
  description : string;
  paymentDate: Date;
  amount : number;
  referenceNumber: string;
}

export class PartialPaymentData{
  paymentDate: Date;
  amount: number;
  totalAmount: number;
  referenceNumber: string;
}

export interface PartialPaymentDataFEModel {
  paymentDate: Date;
  amount: number;
  totalAmount: number; // Optional, if you want to show it in the table
  description: string;  // New field for the description
}

export class accCustomerPartialPaymentOrder {
  orderId: number;
  orderAmount: number;
}

export class AccountCustomerOrdersDetails {
  orders_Results: Todays[];
  accountCustomer: ICustomer;
}

export interface DashboardTemp {
  data: Data;
  //todaysOrders: Todays[];
  //todaysDeliveries: Todays[];
  //todaysPickups: Todays[];
  //todaysCompleted: Todays[];
}

export interface DashboardOrders {
  todaysOrders: Todays[];
  todaysDeliveries: Todays[];
  todaysPickups: Todays[];
  todaysCompleted: Todays[];
  todaysOrdersCustomers?: ICustomer[];
  todaysDeliveriesCustomers?: ICustomer[];
  todaysPickupsCustomers?: ICustomer[];
  todaysCompletedCustomers?: ICustomer[];
}

export interface salesDashboard {
  salesThisYear: SalesYear[];
  salesLastYear: SalesYear[];
}

export interface bookingDashboard {
  bookingPercentage: BookingPercentage;
}

/*public string Phone { get; set; }
            public bool HasPhone { get; set; }
            public bool TwoFactorEnabled { get; set; } */
export class AuthSettings {
  phone: string;
  hasPhone: boolean;
  twoFactorEnabled: boolean;
}

export interface DriversLocation {
  driverName: string;
  truckNumber: string;
  currentLatitude: number;
  currentLongitude: number;
}

export interface OverBooking {
  totalBookings: number;
  unitCount: number;
  franchiseName: string;
  name: string;
  selectedDate: Date;
  productImage: string;
}

export class BookingPercentage {
  bookingPThisYear: string;
  bookingPLastYear: string;
  bookingPYearDiff: number;
  bookingPThisMonth: string;
  bookingPLastYearMonth: string;
  bookingPMonthDiff: number;
  bookingPThisWeek: string;
  bookingPLastYearWeek: string;
  bookingPWeekDiff: number;
  bookingPToday: string;
  bookingPLastYearToday: string;
  bookingPTodayDiff: number;
}

export class Data {
  revenueThisYear: string;
  revenueLastYear: string;
  revenueYearDiff: number;
  revenueThisMonth: string;
  revenueLastYearMonth: string;
  revenueMonthDiff: number;
  revenueThisWeek: string;
  revenueLastYearWeek: string;
  revenueWeekDiff: number;
  revenueToday: string;
  revenueLastYearToday: string;
  revenueTodayDiff: number;
}

export interface SalesYear {
  labels: string;
  datavalue: string;
}

export interface Todays {
  orderID: number;
  deliveryDate: Date;
  pickupDates: string;
  isSigned: boolean;
  deliveryTime: string;
  colorCode: string;
  deliveryAddress: string;
  customerName: string;
  orderImage: string;
  customerID: number;
  accountCustomerId?: number;
  productsList: string;
  isDockDelivery: boolean;
  orderDate: Date;
  orderStatus: string;
  signatureImageUrl: null | string;
  totalCost: string;
  isPaymentStatusApprovedForAccCust: boolean;
  avbCreditLimit: number;
  remainingCurrentBlnc: number;
}


export interface IDeliverySlotsList {
  id: number;
  deliveryTime: string;
  maxDeliveries: number;
  cutOffTime: string;
  franchiseId: number;
  franchiseName: string;
  deliveryCost: string;
  defaultSlot: boolean;
  type: string;
  colorCode: string;
}


export class IDeliverySlotsModel {
  id: number;
  deliveryTime: string;
  maxDeliveries: number;
  cutOffTime: string;
  franchiseId: number;
  deliveryCost: string;
  defaultSlot: boolean;
  isDeleted: boolean;
  type: string;
  colorCode: string;
}

export class editResponse {
  code: number;
  response: string;
}
